import React from 'react';
import { Header, DeviceModal } from '@fiji/common/src/types';
import { ChevronRight, UpdateDisabled } from '@mui/icons-material';
import { Avatar, Chip, IconButton, Paper, Stack, Typography } from '@mui/material';
import { CustomTable, DeleteDeviceModal, MultiItemsDeleteModal } from 'components';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { ListItemTag } from '@brightlayer-ui/react-components';
import {
    useDeleteDeviceMutation,
    useGetAllDevicesQuery,
    useGetDeviceTableQuery,
    useUpdateDeviceTableMutation,
} from '@fiji/common/src/features/deviceManagement/deviceApi';
import { DEVICE_CHIPS, useAppDispatch, useTypedSelector } from '@fiji/common';
import { shallowEqual } from 'react-redux';
import CustomIcon from 'components/CustomIcon';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDebounce, useIsMount } from 'hooks';
import { EditDeviceDrawer, Filter } from 'pages/OrgHome';
import { Device as DeviceIcon } from '@brightlayer-ui/icons-mui';
import {
    deleteDeviceModal,
    deviceFilters,
    resetDeviceChips,
    resetDeviceFilters,
    resetSortPayload,
    selectDeleteDeviceModal,
    setDeviceChips,
    setDeviceFilters,
} from '@fiji/common/src/features/deviceManagement/deviceSlice';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { setDeviceSideBar, setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { moveGroupsDevicesModal } from '@fiji/common/src/features/group/groupSlice';
import { useConfirm } from '@fiji/common/src/hooks';
import { Device } from '@fiji/common/src/types/Device';
import { useGetWidgetByIdQuery } from '@fiji/common/src/features/widgetManagement/widgetApi';
import { InputWithCustomPlaceholder } from 'components/InputWithCustomPlaceholder';
type args = {
    deviceTypeId?: string;
};

export const Assets = ({ deviceTypeId }: args): JSX.Element => {
    const location = useLocation();
    const { id } = useParams();
    const { t } = useTranslation();
    const filterRef = React.useRef<any>(null);
    const isMount = useIsMount();
    const dispatch = useAppDispatch();
    const [filter, setFilter] = React.useState<any>();
    const [dashboardChildDevices, setDashboardChildDevices] = React.useState([]);
    const chips = useTypedSelector((state: any) => state.device.chips);
    const selectedNode = useTypedSelector((state: any) => state?.common?.selectedNode);
    const [isChipEnabled, setIsChipEnabled] = React.useState(true);
    const [searchKey, setSearchKey] = React.useState<string | undefined>(undefined);
    const [, debouncedValue] = useDebounce(undefined, undefined, searchKey);
    const navigate = useNavigate();
    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
    const [deleteDevice, { isLoading: isDeviceDeleting, isSuccess }] = useDeleteDeviceMutation({
        fixedCacheKey: 'deleteDevice',
    });
    const sidebar = useTypedSelector((state) => state.common.deviceSideBar);
    const deleteDeviceModalData = useTypedSelector<DeviceModal>(selectDeleteDeviceModal);
    const { configurablePayload } = useTypedSelector((state) => state.dataGrid, shallowEqual);
    const [paginationPayload, setPaginationPayload] = React.useState({
        page: 0,
        size: 10,
    });
    const filters = useTypedSelector(deviceFilters);
    const [selectedDeviceIds, setSelectedDeviceIds] = React.useState<GridRowSelectionModel>([]);
    const [deviceIdsToBeDeleted, setDeviceIdsToBeDeleted] = React.useState(selectedDeviceIds);
    const [isDashboardChildDeleteModal, setIsDashboardChildDeleteModal] = React.useState(false);
    const isDashboardChildOnly = true;

    const ActionCell = (data: any): JSX.Element => (
        <IconButton disabled>
            <ChevronRight
                className="color-content"
                onClick={(): void => {
                    navigate(`/${currentRealmName}/devicedashboard/${data?.id}`);
                }}
            />
        </IconButton>
    );

    const tableRows: Header[] = [
        {
            header: '',
            isSelectable: true,
        },
        {
            header: 'Status',
            isSortable: true,
            accessor: 'status',
        },
        {
            header: 'Asset',
            accessor: 'asset',
        },
        {
            header: 'Type',
            accessor: 'type',
        },
        {
            header: 'Group',
            accessor: 'group',
            width: '10%',
        },
        {
            header: 'Tags',
            accessor: 'tags',
        },
        {
            header: 'Details',
            accessor: 'details',
        },
        {
            header: 'Upcoming',
            accessor: 'upcoming',
        },
        {
            header: '',
            accessor: 'actions',
            cell: ActionCell,
        },
    ];

    const {
        currentData: widgetByIdData,
        isSuccess: isWidgetByIdSuccess,
        isLoading: isWidgetByIdLoading,
        isFetching: isWidgetByIdFetching,
    } = useGetWidgetByIdQuery<any>(location?.state?.widgetId, { skip: !location?.state?.widgetId });

    const {
        currentData: assetsList,
        isLoading: isAssetListLoading,
        isFetching: isAssetListFetching,
    } = useGetAllDevicesQuery(
        {
            ...paginationPayload,
            ...(!isMount && { searchKey: debouncedValue }),
            filters: { ...filter },
        },
        { refetchOnMountOrArgChange: true, skip: !isWidgetByIdSuccess && location?.state?.widgetId }
    );

    const [filteredDevices, setFilteredDevices] = React.useState(assetsList?.data?.records);

    const assetsListData = assetsList?.data?.records?.map((data: any) => ({
        id: data?.id,
        status: (
            <Avatar sx={{ bgcolor: data?.status?.icon?.web?.style?.backgroundColor }}>
                <CustomIcon
                    family={data?.status?.icon?.web?.family ?? ''}
                    iconName={data?.status?.icon?.web?.name ?? ''}
                    iconProps={{
                        sx: {
                            backgroundColor: data?.status?.icon?.web?.style?.backgroundColor,
                            color: data?.status?.icon?.web?.style?.color,
                        },
                    }}
                />
            </Avatar>
        ),
        asset: (
            <Stack>
                <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'}>
                    {data?.name ?? '--'}
                </Typography>
                <Typography variant="caption" fontSize={'12px'}>
                    {data?.serialNumber ?? '--'}
                </Typography>
            </Stack>
        ),
        type: (
            <Typography variant="body2" fontSize={'14px'}>
                {data?.deviceTypeName ?? '--'}
            </Typography>
        ),
        group: (
            <Stack direction={'row'} alignItems={'center'} className="word-break width-152">
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                    {data?.groupPath?.map((group: any, index: number) => (
                        <Link underline="none" key={`${group}-${index}`} className="black-500 f-14 ">
                            {group}
                        </Link>
                    ))}
                </Breadcrumbs>
            </Stack>
        ),
        tags:
            data?.tags?.length > 0
                ? data?.tags?.map((tag: any, index: number) => (
                      <Typography
                          variant="body2"
                          fontSize={'14px'}
                          className="word-break width-134"
                          key={`${tag}-${index}`}
                      >
                          {tag ?? '--'}
                      </Typography>
                  ))
                : '--',
        details: (
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <ListItemTag
                    label={data?.details ?? '--'}
                    fontColor="#2CA618"
                    backgroundColor="#e7f4e7"
                    fontSize={'10px'}
                    fontWeight={'700'}
                    letterSpacing={'1px'}
                    textTransform={'uppercase'}
                />

                <Stack direction={'row'} alignItems={'center'}>
                    <Typography variant="subtitle1" fontSize={'16px'} fontWeight={'600'}>
                        {data?.details ?? '--'}
                    </Typography>
                    {/* <Typography variant="body1" fontWeight={'300'}>
                        {data?.unit ?? '--'}
                    </Typography> */}
                </Stack>
            </Stack>
        ),
        upcoming: (
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <UpdateDisabled className="gray-500" />
                <Typography variant="body1" fontWeight={'600'}>
                    {data?.upcoming ?? 0}
                </Typography>
            </Stack>
        ),
        actions: ActionCell(data),
    }));

    const [updateTable] = useUpdateDeviceTableMutation();
    useGetDeviceTableQuery('device');

    React.useEffect(() => {
        if (deleteDeviceModalData?.isOpen) {
            handleDeleteConfirmModal();
        }
    }, [deleteDeviceModalData]);

    React.useEffect(() => {
        if (Object.keys(filters).length) {
            for (const key in filters) {
                if (!chips?.includes(DEVICE_CHIPS[key])) {
                    dispatch(setDeviceChips(DEVICE_CHIPS[key]));
                }
            }
            setFilter((prev: any) => ({
                ...prev,
                ...filters,
            }));
        } else if (location?.state?.widgetId) {
            if (widgetByIdData?.data?.config?.multiDevice) {
                setFilter((prev: any) => ({
                    ...prev,
                    deviceIds: widgetByIdData?.data?.config?.assets?.map((assetIdData: any) => assetIdData?.id),
                }));
            } else {
                setFilter(() => {
                    if (selectedNode?.wrapperDeviceType === 'Gateway') {
                        return {
                            parentId: [id ?? selectedNode?.id],
                            modelCategory: [],
                        };
                    }
                    return {
                        group: [selectedNode?.id],
                        modelCategory: [],
                    };
                });
            }
        } else {
            // Retain necessary values in filter when there are no keys in filters
            setFilter(() => {
                if (selectedNode?.wrapperDeviceType === 'Gateway') {
                    return {
                        parentId: [id ?? selectedNode?.id],
                        modelCategory: [],
                    };
                }
                return {
                    group: [selectedNode?.id],
                    modelCategory: [],
                };
            });
        }
    }, [filters, isWidgetByIdSuccess]);

    const handleDelete = async (): Promise<void> => {
        const { error }: any = await deleteDevice({ deviceIds: selectedDeviceIds });
        if (!error) {
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: `${selectedDeviceIds.length} device(s) has been deleted.`,
                    duration: 3000,
                })
            );
            onCancel();
            dispatch(setDeviceSideBar({ isEnable: false, sideBarOpen: false }));
            dispatch(deleteDeviceModal({ isOpen: false }));
        }
    };
    const { isVisible, onCancel, onClick, onConfirm } = useConfirm(handleDelete);

    React.useEffect(() => {
        if (
            (isMount && Object.keys(configurablePayload?.visibleColumns)?.length) ||
            Object.keys(configurablePayload?.pinnedColumns)?.length
        ) {
            void updateTable({ body: configurablePayload, tableType: 'device' });
        }
    }, [configurablePayload]);

    React.useEffect(() => {
        if (selectedDeviceIds?.length > 0) {
            dispatch(
                moveGroupsDevicesModal({
                    deviceIds: selectedDeviceIds?.map((deviceId: any) => ({
                        id: deviceId,
                        name: assetsList?.data?.records?.find((device: any) => device?.id === deviceId)?.name,
                    })),
                })
            );
        }
        if (selectedDeviceIds?.length) {
            dispatch(
                setDeviceSideBar({
                    ...sidebar,
                    isEnable: true,
                    selectedDeviceIds,
                })
            );
        } else {
            dispatch(
                setDeviceSideBar({
                    isEnable: false,
                    selectedDeviceIds,
                })
            );
        }
    }, [selectedDeviceIds]);

    const onDeleteDeviceCancel = (): void => {
        onCancel();
        dispatch(deleteDeviceModal({ isOpen: false }));
    };

    const handleDeleteConfirmModal = (selectedId?: string): void => {
        if (selectedId) {
            setSelectedDeviceIds([selectedId]);
        }
        onClick(selectedId);
        const allDevicesClone = JSON.parse(JSON.stringify(assetsList?.data));
        if (selectedId) {
            allDevicesClone.records = [];
        } else {
            allDevicesClone.records = allDevicesClone.records.filter((device: Device) =>
                selectedDeviceIds.includes(device?.id)
            );
        }
        setFilteredDevices(allDevicesClone?.records);

        setDashboardChildDevices(allDevicesClone?.records);
    };

    const onContinueConfirm = (): void => {
        setIsDashboardChildDeleteModal(false);
        dispatch(deleteDeviceModal({ isOpen: false }));
    };

    const onEditDeviceCancel = (): void => {
        dispatch(
            setDeviceSideBar({
                isEnable: false,
                sideBarOpen: false,
            })
        );
        setSelectedDeviceIds([]);
        setFilteredDevices([]);
    };

    React.useEffect(
        () => () => {
            dispatch(resetDeviceFilters());
            dispatch(resetSortPayload());
            dispatch(resetDeviceChips());
        },
        []
    );

    const handlePaginationChange = (currentPage: number, pageSize: number): void => {
        setPaginationPayload((prev: any) => ({
            ...prev,
            page: currentPage,
            size: pageSize,
        }));
    };

    React.useEffect(() => {
        if (!location?.state?.widgetId) {
            if (selectedNode?.wrapperDeviceType === 'Gateway') {
                setFilter((prev: any) => ({
                    ...prev,
                    parentId: [id ?? selectedNode?.id],
                    modelCategory: deviceTypeId?.length ? deviceTypeId : [],
                }));
            } else {
                setFilter((prev: any) => ({
                    ...prev,
                    group: [selectedNode?.id],
                    modelCategory: deviceTypeId?.length ? deviceTypeId : [],
                }));
            }
        }
    }, [selectedNode]);

    const handleChipDelete = (): void => {
        if (!location?.state?.widgetId) {
            if (selectedNode?.wrapperDeviceType === 'Gateway') {
                setFilter((prev: any) => ({
                    ...prev,
                    parentId: [id ?? selectedNode?.id],
                    modelCategory: [],
                }));
            } else {
                setFilter((prev: any) => ({
                    ...prev,
                    group: [selectedNode?.id],
                    modelCategory: [],
                }));
            }
        }
        setIsChipEnabled(false);
    };

    React.useEffect(() => {
        if (!selectedNode?.wrapperDeviceType && selectedNode) {
            dispatch(setDeviceFilters({ key: 'groups', value: [selectedNode.id] }));
        } else if (selectedNode && selectedNode?.wrapperDeviceType === 'Gateway') {
            dispatch(setDeviceFilters({ key: 'parentId', value: [selectedNode.id] }));
        }
    }, [selectedNode]);
    return (
        <Stack p={3}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={'row'} spacing={3} alignItems={'center'}>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        className="border-2 border-radius-4"
                    >
                        <InputWithCustomPlaceholder
                            id="searchKey"
                            data-cy={'searchKey'}
                            className="margin-left-10"
                            value={searchKey}
                            onChange={(e: any): void => {
                                setSearchKey(e?.target?.value?.trimStart());
                            }}
                            placeholder={'COMMON:SEARCH_PLACEHOLDER'}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Stack>
                    <Filter ref={filterRef} />
                </Stack>
                {isChipEnabled && deviceTypeId && (
                    <Stack direction={'row'} gap={2} flexWrap={'wrap'}>
                        <Chip label="Model Category" onDelete={handleChipDelete} />
                    </Stack>
                )}
            </Stack>

            <Stack direction="row" marginBottom={'16px'} justifyContent={'end'} spacing={1}>
                {!deviceTypeId &&
                    chips.map((key: string) => (
                        <Chip
                            key={`unique${key}`}
                            label={key}
                            onDelete={filterRef?.current?.toggleFilterDrawer('right', true)}
                        />
                    ))}
            </Stack>

            <Paper className="margin-y-16">
                <CustomTable
                    data={assetsListData}
                    isPagination
                    handlePageChange={handlePaginationChange}
                    // containerClass="custom-data-table"
                    isLoading={isWidgetByIdLoading || isWidgetByIdFetching || isAssetListLoading || isAssetListFetching}
                    keyToTraverse="id"
                    total={10}
                    headers={tableRows}
                    noDataFoundIcon={<DeviceIcon fontSize="inherit" />}
                    noDataFoundTitle="No Upcoming Events"
                    noDataFoundDescription={
                        'Upcoming events are things that may happen in the future. This includes predictions, scheduled service, and more.'
                    }
                />
            </Paper>
            <DeleteDeviceModal
                selectedIds={selectedDeviceIds as any}
                deleteSuccess={isSuccess}
                handleIdsToBeDeleted={setDeviceIdsToBeDeleted}
                isLoading={isDeviceDeleting}
                titleKey="name"
                titleKey1="serialNumber"
                keyToTraverse="id"
                subtitleKey="groupPath"
                filteredData={dashboardChildDevices ?? []}
                handleModalClose={onDeleteDeviceCancel}
                isOpen={isDashboardChildDeleteModal}
                header={t('DEVICE_MANAGEMENT.MULTI_ITEMS_MODAL_HEADERS')}
                handleConfirmDelete={onContinueConfirm}
                modalDescription={t('DEVICE_MANAGEMENT.MULTI_ITEMS_MODAL_DESCRIPTION')}
                deleteModalHeader={t('DEVICE_MANAGEMENT.MULTI_ITEMS_MODAL_HEADER')}
                deleteModalDescription={
                    isDashboardChildOnly
                        ? t('DEVICE_MANAGEMENT.DASHBOARD_CHILD_DEVICE_DELETE_DESCRIPTION')
                        : t('DEVICE_MANAGEMENT.DASHBOARD_CHILD_DEVICE_DELETE_WITH_OTHER_DEVICES_DESCRIPTION')
                }
            />
            <MultiItemsDeleteModal
                titleKey="name"
                titleKey1="serialNumber"
                keyToTraverse="id"
                subtitleKey="groupPath"
                filteredData={filteredDevices ?? []}
                selectedIds={selectedDeviceIds as any}
                isLoading={isDeviceDeleting}
                deleteSuccess={isSuccess}
                handleIdsToBeDeleted={setDeviceIdsToBeDeleted}
                handleModalClose={onDeleteDeviceCancel}
                isOpen={isVisible}
                handleConfirmDelete={onConfirm}
                header={t('DEVICE_MANAGEMENT.MULTI_ITEMS_MODAL_HEADER')}
                modalDescription={t('DEVICE_MANAGEMENT.MULTI_ITEMS_MODAL_DESCRIPTION')}
                deleteModalHeader={t('DEVICE_MANAGEMENT.MULTI_ITEMS_MODAL_HEADER')}
                deleteModalDescription={t('DEVICE_MANAGEMENT.DELETE_MODAL_DESCRIPTION', {
                    total: deviceIdsToBeDeleted?.length,
                })}
                deleteButtonText={t('DEVICE_MANAGEMENT.DELETE_DEVICE_BTN')}
            />
            <EditDeviceDrawer selectedDevices={selectedDeviceIds} onEditDeviceCancel={onEditDeviceCancel} />
        </Stack>
    );
};
