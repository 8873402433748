import React from 'react';

import {
    widgetList,
    setDashboardId,
    resetDashboard,
    configureSummaryValues,
    mqttTopics,
    removeSummaryWidgetLoader,
    setWidgetList,
} from '@fiji/common/src/features/dashboardManagement/commonDashboardSlice';
import { CustomTransComponent, DynamicWidget, Loader } from '../../components';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';

import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import RGL, { WidthProvider } from 'react-grid-layout';
import { Stack, Box, Button, Card, CardContent, CardHeader, Typography, Divider } from '@mui/material';
import { resetLocalDashboard } from '@fiji/common/src/features/dashboardManagement/dashboardSlice';
import { CreateDashboardModal } from './CreateDashboard';
import Widgets from '@mui/icons-material/Widgets';
import DesignServices from '@mui/icons-material/DesignServices';

import { useGetActiveDashboardsQuery } from '@fiji/common/src/features/dashboardManagement/dashboardApi';
import {
    useCommonWidgetConfiguration,
    useConfirm,
    useDashboardCommonHandlers,
    useGetWidgetDetails,
} from '@fiji/common/src/hooks';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { AddVirtualModal } from 'pages/VirtualLoadDetails/AddVirtualModal';
import { DetailsCardSkeleton } from 'pages/WidgetManagement/common/Skeletons';
import { useRBAC } from 'hooks';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useHandleVirtualLoads } from 'pages/VirtualLoadDetails/hooks';
import { GRID_COLS, GRID_ROWS } from '@fiji/common/src/constants';
import { subscribeTopic } from '@fiji/common/src/mqtt_connection/mqttConnection';
import { api } from '@fiji/common/src/app/api/baseApi';
import { ScoreModal } from 'pages/WidgetManagement/common/ScoreModal';
import { downloadFile, downloadFileFromLink } from 'utils/helpers';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { useTranslation } from 'react-i18next';
import { CustomEmptyState } from 'components/CustomEmptyState';
import { SnoozeHeader } from './SnoozeHeader';
import { resetTrendsLegendData } from '@fiji/common/src/features/widgetManagement/trendsWidgetSlice';
import {
    getWidgetTypeValidationConfig,
    isWidgetApplicable,
    isWidgetConfigured,
    customEndpointSelector,
    systemTimezone,
    timeStampHandler,
} from '@fiji/common/src/utils/helpers';
import { ErrorOutline } from '@mui/icons-material';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { ApiEndpointType, ApiResponseType } from '@fiji/common/src/features/common/commonTypes';
import { UserProfile } from '@fiji/common/src/types';

const ResponsiveReactGridLayout = WidthProvider(RGL);

export const Summary = (): JSX.Element => {
    const theme: any = useTheme();
    const { t } = useTranslation();
    const modalRef = React.useRef<any>(null);
    const trendsRef = React.useRef<any>(null);
    const currentOrg = useTypedSelector(selectedOrg);
    const { deviceId } = useParams();
    const permissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permissions);
    const canCreateSystemDashboard = hasPermission('create-system-dashboards');
    const canCreateUserDashboard = hasPermission('create-user-dashboards');
    const widgets = useTypedSelector(widgetList);
    const loadingState = useTypedSelector((rootState) => rootState.commonDashboard.loaders);

    const mqttConnectionStatus = useTypedSelector((state) => state['common']['mqttConnectionStatus']);

    const topics = useTypedSelector(mqttTopics);
    const scoreModalRef = React.useRef<any>(null);

    const {
        data: dashboards,
        isLoading,
        isFetching,
        isSuccess,
        isError,
    }: any = useGetActiveDashboardsQuery(
        {
            params: {
                resourceType: 'DEVICE',
                resourceTypeId: deviceId,
            },
        },
        {
            skip: !currentOrg?.id || !deviceId,
        }
    );

    const group = useTypedSelector((state) => state.common.selectedNode);
    const dispatch = useAppDispatch();

    const {
        onClick,
        data: virtualLoadWidgetData,
        onConfirm,
        isVisible,
        onCancel,
    } = useConfirm((args: any): void => handleSubmit(args) as any);

    const { isSuccess: createIsSuccess, handleSubmit } = useHandleVirtualLoads(virtualLoadWidgetData);

    const { isLoading: isConfigLoading } = useCommonWidgetConfiguration(dashboards?.data, {
        deviceId: deviceId,
    });

    const { data: profileDetails } = useGetUserProfileQuery({}) as ApiEndpointType<ApiResponseType<UserProfile>>;

    const { payload, response: widgetResponse } = useGetWidgetDetails({
        selectorType: 'commonDashboard',
        dataGetter: {
            groupId: group?.id ?? currentOrg?.id,
            deviceId: deviceId,
        },
    });

    const { handleDeviceCommands, handleDeviceLoads } = useDashboardCommonHandlers(deviceId);

    const handleMapConfig = (args: any): any => {
        const selectedWidgetClone = JSON.parse(
            JSON.stringify(widgets?.find((item: any) => item.id === args.widgetId) ?? {})
        );
        dispatch(
            (api as any)?.endpoints?.['deviceMap']?.initiate({
                ...args?.bounds,
                filters: {
                    ...(selectedWidgetClone?.config?.filters
                        ? selectedWidgetClone?.config?.filters
                        : { deviceIds: [deviceId] }),
                },
            })
        )?.then(({ data }: any) => {
            if (data) {
                selectedWidgetClone['config']['secondary'] = data?.data?.map((item: any) => ({
                    coordinates: item.coordinates,
                    deviceCount: item.deviceCount,
                    name: item?.deviceList?.[0]?.name ?? '',
                    id: item?.deviceList?.[0]?.id,
                    deviceList: item?.deviceList,
                }));
                dispatch(configureSummaryValues({ widgetId: args?.widgetId, config: selectedWidgetClone.config }));
            }
        });
    };

    // const handleLoadsWidget = (): void => {
    //     //
    // };

    const downloadTrends = (widgetId: any): void => {
        const selectedWidgetClone = JSON.parse(
            JSON.stringify(widgets?.find((item: any) => item?.id === widgetId)?.config ?? {})
        );

        if (selectedWidgetClone) {
            dispatch(
                (api as any)?.endpoints?.['exportTrends']?.initiate({
                    body: {
                        exportRequests: payload['trends'](selectedWidgetClone)['payload'],
                        from: payload['trends'](selectedWidgetClone)['from'],
                        to: payload['trends'](selectedWidgetClone)['to'],
                        types: ['DOWNLOAD'],
                    },
                    params: { type: 'CSV' },
                })
            ).then((response: any) => {
                if (response?.data?.data) {
                    downloadFileFromLink(response?.data?.data, 'trends');
                    dispatch(setMessageContent({ isOpen: true, message: t('COMMON:FILE_DOWNLOAD_SUCCESS') }));
                }
            });
        }
    };

    const handleTrendsDurationPayload = (trendsPayload: any): void => {
        const selectedWidgetClone = JSON.parse(
            JSON.stringify(widgets?.find((item: any) => item?.id === trendsPayload?.widgetId)?.config ?? {})
        );
        const newPayload = JSON.parse(JSON.stringify(payload['trends'](selectedWidgetClone)));
        newPayload['from'] = trendsPayload?.activeDuration?.from;
        newPayload['to'] = trendsPayload?.activeDuration?.to;

        if (selectedWidgetClone) {
            dispatch(removeSummaryWidgetLoader(trendsPayload?.widgetId));
            dispatch((api as any)?.endpoints?.['getDeviceTrends']?.initiate(newPayload, { forceRefetch: true })).then(
                (response: any) => {
                    widgetResponse['trends']([response], trendsPayload?.widgetId, {
                        duration:
                            (trendsPayload?.activeDuration?.to - trendsPayload?.activeDuration?.from) / 1000 / 60 / 60,
                    });
                }
            );
        }
    };

    const handleRefreshCallback = (widgetId: any): void => {
        const selectedWidgetClone = JSON.parse(
            JSON.stringify(widgets?.find((item: any) => item?.id === widgetId)?.config ?? {})
        );
        const newPayload = JSON.parse(JSON.stringify(payload['details'](selectedWidgetClone)));

        if (selectedWidgetClone) {
            dispatch(removeSummaryWidgetLoader(widgetId));
            dispatch((api as any)?.endpoints?.['getChannelDetails']?.initiate(newPayload, { forceRefetch: true })).then(
                (response: any) => {
                    widgetResponse['details']([response], widgetId);
                }
            );
        }
    };

    const handleLegendsPayload = (selectionPayload: any): void => {
        const selectedWidgetClone = JSON.parse(
            JSON.stringify(widgets?.find((item: any) => item?.id === selectionPayload?.widgetId) ?? {})
        );
        if (selectedWidgetClone?.config) {
            selectedWidgetClone['config']['secondary'] = selectionPayload.payload;
            dispatch(configureSummaryValues({ widgetId: selectionPayload?.widgetId, config: selectedWidgetClone }));
        }

        if (selectedWidgetClone?.config) {
            dispatch(
                (api as any)?.endpoints?.['getDeviceTrends']?.initiate(payload['trends'](selectedWidgetClone?.config), {
                    forceRefetch: true,
                })
            ).then((response: any) => {
                widgetResponse['trends']([response], selectionPayload?.widgetId, {
                    selectedWidget: selectedWidgetClone,
                });
            });
        }
    };

    const mqttResponseHandler = (message: any, messageTopic: string): void => {
        //
        const splitedArr: any = messageTopic.split('/');
        const requiredTopic = splitedArr[0]?.concat(`/${splitedArr[1]}`);
        switch (requiredTopic) {
            case 'BSSRM/TREND':
                trendsRef?.current?.addPoints({
                    timestamp: message?.timestamp,
                    channelId: message?.key,
                    deviceId: message?.deviceId,
                    unit: message?.unit,
                    value: +message?.value,
                });
                break;
            case 'BSSRM/COMMAND_TOPIC': {
                if (message?.type === 'command' || message?.type === 'runTest') {
                    const selectedDeviceId = splitedArr[splitedArr?.length - 1];
                    const widgetsClone = JSON.parse(JSON.stringify(widgets));
                    const filteredWidgets = widgetsClone?.filter((item: any) => {
                        if (
                            item?.widgetType?.id === 'command_bar' &&
                            item?.config?.secondary?.some(
                                (channelData: any) => channelData?.deviceId === selectedDeviceId
                            )
                        ) {
                            return item;
                        }
                    });
                    dispatch(setMessageContent({ isOpen: true, message: message?.message }));

                    filteredWidgets?.forEach((widget: any) => {
                        const widgetClone = JSON.parse(JSON.stringify(widget?.config));

                        widgetClone?.secondary?.forEach((channel: any) => {
                            channel?.channels?.forEach((item: any) => {
                                if (item?.isLoading) {
                                    delete item?.isLoading;
                                }
                            });
                        });
                        // dispatch(removeSummaryWidgetLoader(widget?.id));
                        dispatch(
                            (api as any)?.endpoints?.['getAvailableCommands']?.initiate(
                                payload['command_bar-getAvailableCommands'](widgetClone),
                                {
                                    forceRefetch: true,
                                }
                            )
                        ).then((response: any) => {
                            widgetResponse['command_bar']([response], widget?.id);
                        });
                        // dispatch(configureSummaryValues({ widgetId: widget?.id, config: widgetClone }));
                    });
                }

                break;
            }
            default:
                break;
        }
    };

    React.useEffect(() => {
        if (mqttConnectionStatus === true && Object.keys(loadingState)?.length === widgets?.length) {
            subscribeTopic(topics, mqttResponseHandler);
        }
    }, [mqttConnectionStatus, topics, loadingState]);

    // const handleLoads;

    const handleWidgetFilter = (event: any, id: any, widgetToUpdate: any): void => {
        const { from, to, prevFrom, prevTo } =
            widgetToUpdate?.widgetType?.id === 'load_trends'
                ? timeStampHandler(
                      profileDetails?.data?.timezone ?? systemTimezone,
                      profileDetails?.data?.calendarWeek,
                      event?.target?.value,
                      null,
                      null,
                      'load_trends'
                  )
                : timeStampHandler(
                      profileDetails?.data?.timezone ?? systemTimezone,
                      profileDetails?.data?.calendarWeek,
                      event?.target?.value
                  );
        const { config } = widgetToUpdate;
        const eventValue = event?.target?.value;
        const data = payload[widgetToUpdate?.widgetType?.id]({ ...config });

        const generatePayloadData = (): any => {
            switch (widgetToUpdate?.widgetType?.id) {
                case 'metrics':
                case 'load_trends':
                    return {
                        ...data,
                        currentState: { startTime: from, endTime: to },
                        previousState: { startTime: prevFrom, endTime: prevTo },
                        timeFilter: eventValue?.toUpperCase(),
                    };
                case 'weather':
                    return {
                        ...data,
                        timeFilter: eventValue?.toUpperCase(),
                    };
                case 'aggregated_trends':
                    return {
                        ...data,
                        startTime: from,
                        endTime: to,
                        timeFilter: eventValue?.toUpperCase(),
                        unit: config?.unitType,
                    };
                default:
                    return {
                        ...data,
                        startTime: from,
                        endTime: to,
                        timeFilter: eventValue?.toUpperCase(),
                    };
            }
        };

        const payloadData = generatePayloadData();

        const endpoint: any = customEndpointSelector(widgetToUpdate?.widgetType?.id, config?.type)?.[0]?.['endpoint'];
        if (!endpoint) {
            console.error('Invalid endpoint');
            return;
        }

        dispatch((api as any)?.endpoints?.[endpoint]?.initiate(payloadData, { forceRefetch: true }))
            .then((response: any) => {
                if (!response?.isError) {
                    dispatch(
                        setWidgetList({
                            data: {
                                ...widgetToUpdate,
                                config: {
                                    ...widgetToUpdate.config,
                                    secondary: response?.data?.data,
                                    ...((widgetToUpdate?.widgetType?.id === 'load_trends' ||
                                        widgetToUpdate?.widgetType?.id === 'weather' ||
                                        widgetToUpdate?.widgetType?.id === 'aggregated_trends') && {
                                        selectedTimePeriod: eventValue,
                                    }),
                                },
                            },
                            widgetId: id,
                        })
                    );
                } else {
                    handleError(widgetToUpdate, id);
                }
            })
            .catch((error: any) => {
                console.error('error: ', error);
                handleError(widgetToUpdate, id);
            });
    };

    const handleError = (widgetToUpdate: any, id: any): void => {
        dispatch(
            setWidgetList({
                data: {
                    ...widgetToUpdate,
                    config: {
                        ...widgetToUpdate.config,
                        secondary: {},
                    },
                },
                widgetId: id,
            })
        );
    };

    const handleUnitTypeFilter = (event: any, id: any, widgetToUpdate: any): void => {
        const { config } = widgetToUpdate;
        const { from, to } = timeStampHandler(
            profileDetails?.data?.timezone ?? systemTimezone,
            profileDetails?.data?.calendarWeek,
            config?.selectedTimePeriod
        );
        const eventValue = event?.target?.value;
        const data = payload[widgetToUpdate?.widgetType?.id]({ ...config });
        const payloadData = {
            ...data,
            startTime: from,
            endTime: to,
            timeFilter: config?.selectedTimePeriod?.toUpperCase(),
            unit: eventValue,
        };

        dispatch(
            (api as any)?.endpoints?.[
                customEndpointSelector(widgetToUpdate?.widgetType?.id, config?.type)?.[0]?.['endpoint']
            ]?.initiate(payloadData)
        )
            .then((response: any) => {
                if (!response?.isError) {
                    dispatch(
                        setWidgetList({
                            data: {
                                ...widgetToUpdate,
                                config: {
                                    ...widgetToUpdate.config,
                                    secondary: response?.data?.data,
                                    unitType: eventValue,
                                },
                            },
                            widgetId: id,
                        })
                    );
                } else {
                    dispatch(
                        setWidgetList({
                            data: {
                                ...widgetToUpdate,
                                config: {
                                    ...widgetToUpdate.config,
                                    secondary: {},
                                },
                            },
                            widgetId: id,
                        })
                    );
                }
            })
            .catch((error: any) => {
                console.error('error: ', error);
            });
    };

    const handleDownloadCSV = (widgetToUpdate: any): void => {
        const { config } = widgetToUpdate;
        const { from, to } = timeStampHandler(
            profileDetails?.data?.timezone ?? systemTimezone,
            profileDetails?.data?.calendarWeek,
            config?.selectedTimePeriod
        );
        const data = payload[widgetToUpdate?.widgetType?.id]({ ...config });
        const payloadData = {
            ...data,
            startTime: from,
            endTime: to,
            timeFilter: config?.selectedTimePeriod?.toUpperCase(),
            unit: config?.unitType,
            download: true,
            email: [],
            fileType: 'CSV',
            sendEmail: false,
        };

        dispatch(
            (api as any)?.endpoints?.[
                customEndpointSelector(widgetToUpdate?.widgetType?.id, config?.downloadType)?.[0]?.['endpoint']
            ]?.initiate(payloadData)
        )
            .then((response: any) => {
                const fetchUrl: any = response?.data?.data?.filter(
                    (dataUrl: any) => dataUrl?.reportType === 'Download'
                );
                downloadFile(fetchUrl[0]?.url, 'Aggregated_Trends.csv');
                dispatch(
                    setMessageContent({
                        isOpen: true,
                        message: fetchUrl[0]?.message,
                        duration: 3000,
                    })
                );
            })
            .catch((error: any) => {
                console.error('error: ', error);
            });
    };

    const handleRefreshWeatherData = (id: any, widgetToUpdate: any): void => {
        const { config } = widgetToUpdate;
        const data = payload[widgetToUpdate?.widgetType?.id]({ ...config });
        const payloadData = {
            ...data,
        };
        dispatch(
            (api as any)?.endpoints?.[
                customEndpointSelector(widgetToUpdate?.widgetType?.id, config?.type)?.[0]?.['endpoint']
            ]?.initiate(payloadData, { forceRefetch: true })
        )
            .then((response: any) => {
                if (!response?.isError) {
                    dispatch(
                        setWidgetList({
                            data: {
                                ...widgetToUpdate,
                                config: {
                                    ...widgetToUpdate.config,
                                    secondary: response?.data?.data,
                                },
                            },
                            widgetId: id,
                        })
                    );
                } else {
                    dispatch(
                        setWidgetList({
                            data: {
                                ...widgetToUpdate,
                                config: {
                                    ...widgetToUpdate.config,
                                    secondary: {},
                                },
                            },
                            widgetId: id,
                        })
                    );
                }
            })
            .catch((error: any) => {
                console.error('error: ', error);
            });
    };

    const renderLoader = (type: any): JSX.Element => {
        if (getWidgetTypeValidationConfig(type)['loaderType'] === 'skeleton') {
            return (
                <DetailsCardSkeleton
                    secondarylength={5}
                    {...(getWidgetTypeValidationConfig(type)?.['primarySkeletonCount'] && {
                        primarylength: getWidgetTypeValidationConfig(type)?.['primarySkeletonCount'],
                    })}
                />
            );
        }
        return <Loader size={55} />;
    };

    React.useEffect(() => {
        dispatch(resetLocalDashboard());
        dispatch(resetTrendsLegendData());
    }, []);

    React.useEffect(() => () => dispatch(resetDashboard()), []);

    React.useEffect(() => {
        if (dashboards) {
            dispatch(setDashboardId(dashboards?.data?.id));
        }
    }, [dashboards]);

    // React.useEffect(() => {
    //     if (widgets.length !== Object.values(loadingState).length) {
    //         document.body.style.pointerEvents = 'none';
    //     } else {
    //         document.body.style.pointerEvents = 'auto';
    //     }
    // }, [loadingState, widgets]);

    React.useEffect(() => {
        if (createIsSuccess) onCancel();
    }, [createIsSuccess]);

    return (
        <>
            <SnoozeHeader />

            {widgets
                ?.filter((item: any) => item?.config?.isPinned)
                ?.map((widget: any) => (
                    <>
                        {!loadingState[widget?.id] && (
                            <Card sx={{ height: '100%' }}>
                                <CardContent className="padding-0 custom-card-height">
                                    {renderLoader(widget?.widgetType?.id)}
                                </CardContent>
                            </Card>
                        )}
                        {loadingState[widget?.id] && (
                            <DynamicWidget
                                mode="pinned-view"
                                widgetTypeId={widget?.widgetType?.id}
                                {...(widget?.widgetType?.id === 'map' && {
                                    countKey: 'deviceCount',
                                    widgetData: widget?.config,
                                    onIdle: handleMapConfig,
                                    widgetId: widget?.id,
                                    clustering: false,
                                })}
                                {...(widget?.widgetType?.id === 'score' && {
                                    learnMoreCallback: (): void => {
                                        scoreModalRef?.current?.onClick();
                                    },
                                })}
                                {...(widget?.widgetType?.id === 'trends' && {
                                    payloadChangeHandler: (widgetPayload: any): void =>
                                        handleTrendsDurationPayload({
                                            ...widgetPayload,
                                            widgetId: widget?.id,
                                        }),
                                    downloadHandler: (): void => downloadTrends(widget?.id),
                                    handlerLegendsPayload: (legends: any): void =>
                                        handleLegendsPayload({
                                            ...legends,
                                            widgetId: widget?.id,
                                        }),
                                })}
                                {...(widget?.widgetType?.id === 'command_bar' && {
                                    commandHandler: (commandBarData: any): void =>
                                        handleDeviceCommands(commandBarData, widget?.id),
                                })}
                                widgetData={{
                                    internalCdnJsLink: widget?.widgetType?.internalCdnJsLink,
                                    ...(widget?.config
                                        ? {
                                              ...widget?.config,
                                              widgetName: widget?.name,
                                              widgetTypeId: widget?.widgetType?.id,
                                          }
                                        : {
                                              primary: [],
                                              secondary: [],
                                              secondaryChannelCount: 6,
                                          }),
                                }}
                            />
                        )}
                    </>
                ))}
            <Stack className="margin-16">
                {!dashboards?.data && (isSuccess || isError) && !isLoading && !isFetching && !isConfigLoading ? (
                    <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: 'calc(100vh - 200px)' }}>
                        <CustomEmptyState
                            icon={<Widgets fontSize="inherit" />}
                            title={(<CustomTransComponent translationKey={'WIDGETS:NO_WIDGETS_FOUND'} />) as any}
                            description={(<CustomTransComponent translationKey={'WIDGETS:NO_WIDGET_DESC'} />) as any}
                            actions={
                                (canCreateSystemDashboard || canCreateUserDashboard) && (
                                    <Button
                                        sx={{
                                            border: `1px solid ${theme?.palette?.primary?.main}`,
                                            '&:hover': {
                                                backgroundColor: theme?.palette?.primary?.[50],
                                            },
                                        }}
                                        variant="outlined"
                                        color="primary"
                                        startIcon={<DesignServices />}
                                        onClick={(): void =>
                                            modalRef?.current?.handleModalAction?.(true, {
                                                assignType: 'DEVICE',
                                                assignTypeIds: [deviceId],
                                            })
                                        }
                                    >
                                        <CustomTransComponent translationKey={'DASHBOARDS:CREATE_DASHBOARD'} />
                                    </Button>
                                )
                            }
                        />
                    </Stack>
                ) : (
                    <Box sx={{ position: 'relative', width: '100%' }}>
                        <ResponsiveReactGridLayout
                            className="layout w-100 view-dashboard"
                            isResizable={false}
                            compactType="vertical"
                            draggableHandle=".drag-handle"
                            layout={
                                widgets?.length === dashboards?.data?.widgetIdList?.length &&
                                dashboards?.data?.widgetLayout
                            }
                            style={{ position: 'absolute' }}
                            cols={GRID_COLS}
                            rowHeight={GRID_ROWS}
                        >
                            {Boolean(widgets?.length) &&
                                widgets?.map(
                                    (widget: any) =>
                                        !widget?.config?.isPinned && (
                                            <div key={widget?.id} style={{ zIndex: 999 }}>
                                                {!loadingState[widget?.id] && (
                                                    <Card sx={{ height: '100%' }}>
                                                        <CardContent className="padding-0 custom-card-height">
                                                            {renderLoader(widget?.widgetType?.id)}
                                                        </CardContent>
                                                    </Card>
                                                )}

                                                {loadingState[widget?.id] &&
                                                    isWidgetApplicable(widget, 'DEVICE') &&
                                                    isWidgetConfigured(widget) && (
                                                        <>
                                                            <DynamicWidget
                                                                mode="view"
                                                                widgetRef={trendsRef}
                                                                widgetTypeId={widget?.widgetType?.id}
                                                                {...(widget?.widgetType?.id === 'virtual_loads' && {
                                                                    addVirtualLoad: (): void =>
                                                                        onClick({
                                                                            widgetId: widget?.id,
                                                                            widgetType: widget?.widgetType?.id,
                                                                        }),
                                                                })}
                                                                {...(widget?.widgetType?.id === 'score' && {
                                                                    learnMoreCallback: (): void => {
                                                                        scoreModalRef?.current?.onClick();
                                                                    },
                                                                })}
                                                                {...(widget?.widgetType?.id === 'details' && {
                                                                    refreshCallback: (): void => {
                                                                        handleRefreshCallback(widget?.id);
                                                                    },
                                                                })}
                                                                {...(widget?.widgetType?.id === 'trends' && {
                                                                    payloadChangeHandler: (widgetPayload: any): void =>
                                                                        handleTrendsDurationPayload({
                                                                            ...widgetPayload,
                                                                            widgetId: widget?.id,
                                                                        }),
                                                                    downloadHandler: (): void =>
                                                                        downloadTrends(widget?.id),
                                                                    handlerLegendsPayload: (legends: any): void =>
                                                                        handleLegendsPayload({
                                                                            ...legends,
                                                                            widgetId: widget?.id,
                                                                        }),
                                                                })}
                                                                {...(widget?.widgetType?.id === 'command_bar' && {
                                                                    commandHandler: (commandBarData: any): void =>
                                                                        handleDeviceCommands(
                                                                            commandBarData,
                                                                            widget?.id
                                                                        ),
                                                                })}
                                                                {...(widget?.widgetType?.id === 'loads' && {
                                                                    loadsHandler: (loadsData: any): void =>
                                                                        handleDeviceLoads(loadsData, widget?.id),
                                                                })}
                                                                {...(widget?.widgetType?.id === 'map' && {
                                                                    countKey: 'deviceCount',
                                                                    widgetData: widget?.config,
                                                                    onIdle: handleMapConfig,
                                                                    widgetId: widget?.id,
                                                                    clustering: false,
                                                                })}
                                                                {...((widget?.widgetType?.id ===
                                                                    'consumption_breakdown' ||
                                                                    widget?.widgetType?.id === 'metrics' ||
                                                                    widget?.widgetType?.id === 'load_trends' ||
                                                                    widget?.widgetType?.id === 'weather' ||
                                                                    widget?.widgetType?.id === 'aggregated_trends') && {
                                                                    widgetFilter: (event: any, id: any): void => {
                                                                        const widgetToUpdate = widgets?.find(
                                                                            (widgetId: any) => widgetId?.id === id
                                                                        );
                                                                        handleWidgetFilter(event, id, widgetToUpdate);
                                                                    },
                                                                })}
                                                                {...(widget?.widgetType?.id === 'aggregated_trends' && {
                                                                    unitFilter: (event: any, id: any): void => {
                                                                        const widgetToUpdate = widgets?.find(
                                                                            (widgetId: any) => widgetId?.id === id
                                                                        );
                                                                        handleUnitTypeFilter(event, id, widgetToUpdate);
                                                                    },
                                                                    downloadCSVHandler: (id: any): void => {
                                                                        const widgetToUpdate = widgets?.find(
                                                                            (widgetId: any) => widgetId?.id === id
                                                                        );
                                                                        handleDownloadCSV(widgetToUpdate);
                                                                    },
                                                                })}
                                                                {...(widget?.widgetType?.id === 'weather' && {
                                                                    fetchWeatherData: (id: any): void => {
                                                                        const widgetToUpdate = widgets?.find(
                                                                            (widgetId: any) => widgetId?.id === id
                                                                        );
                                                                        handleRefreshWeatherData(id, widgetToUpdate);
                                                                    },
                                                                })}
                                                                widgetData={{
                                                                    internalCdnJsLink:
                                                                        widget?.widgetType?.internalCdnJsLink,
                                                                    ...(widget?.config
                                                                        ? {
                                                                              ...widget?.config,
                                                                              widgetName: widget?.name,
                                                                              widgetTypeId: widget?.widgetType?.id,
                                                                              widgetTypeName: widget?.widgetType?.name,
                                                                              id: widget?.id,
                                                                          }
                                                                        : {
                                                                              primary: [],
                                                                              secondary: [],
                                                                              secondaryChannelCount: 6,
                                                                          }),
                                                                }}
                                                            />
                                                        </>
                                                    )}

                                                {loadingState[widget?.id] && !isWidgetApplicable(widget, 'DEVICE') && (
                                                    <Card className="margin-left-0" sx={{ height: '100%' }}>
                                                        <CardHeader
                                                            title={
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="primary"
                                                                    fontWeight={'600'}
                                                                    fontSize={'14px'}
                                                                    padding={'16px'}
                                                                >
                                                                    {widget?.name ?? '-'}
                                                                </Typography>
                                                            }
                                                            className="border-bottom-1 padding-0"
                                                        />
                                                        <Divider />
                                                        <CardContent>
                                                            <CustomEmptyState
                                                                icon={<ErrorOutline className="height-100 width-100" />}
                                                                title={`${widget?.name} Not Applicable`}
                                                                className="padding-y-80"
                                                                description="This widget type does not support selected group or device."
                                                            />
                                                        </CardContent>
                                                    </Card>
                                                )}

                                                {loadingState[widget?.id] &&
                                                    isWidgetApplicable(widget, 'DEVICE') &&
                                                    !isWidgetConfigured(widget) && (
                                                        <Card className="margin-left-0" sx={{ height: '100%' }}>
                                                            <CardHeader
                                                                title={
                                                                    <Typography
                                                                        variant="subtitle2"
                                                                        color="primary"
                                                                        fontWeight={'600'}
                                                                        fontSize={'14px'}
                                                                        padding={'16px'}
                                                                    >
                                                                        {widget?.name ?? '-'}
                                                                    </Typography>
                                                                }
                                                                className="border-bottom-1 padding-0"
                                                            />
                                                            <Divider />
                                                            <CardContent>
                                                                <CustomEmptyState
                                                                    icon={
                                                                        <ErrorOutline className="height-100 width-100" />
                                                                    }
                                                                    title={`${widget?.name} Not Configured`}
                                                                    className="padding-y-80"
                                                                    description="This widget type is not configured."
                                                                />
                                                            </CardContent>
                                                        </Card>
                                                    )}
                                            </div>
                                        )
                                )}
                        </ResponsiveReactGridLayout>
                    </Box>
                )}
            </Stack>
            {/* Create Dashboard Modal */}
            <CreateDashboardModal key="createsn@d3" ref={modalRef} />
            <AddVirtualModal key="virtual@1231" isVisible={isVisible} onConfirm={onConfirm} onCancel={onCancel} />
            <ScoreModal key="score@12de" ref={scoreModalRef} />
            {(isLoading || isFetching || isConfigLoading) && <Loader size={60} />}
        </>
    );
};
