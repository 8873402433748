import { RootState } from '../../app/store';
import { GRID_COLS } from '../../constants';
import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { apiResponseHelper, setDashboardWidgets } from './helpers';
import { isNullOrUndefined } from '@fiji/common/src/utils/helpers';

const intitialState = {
    widgetList: [],
    widgetDetails: {},
    dashboardWidgetIds: [],
    dashboardId: undefined,
    layout: [],
    loaders: {},
    apiResponse: [],
    removedWidgets: [],
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: intitialState,
    reducers: {
        resetLocalDashboard: () => {
            return intitialState;
        },
        resetLoaders: (state: RootState) => {
            state.loaders = {};
        },
        setLayout: (state: RootState, action: PayloadAction<any>) => {
            state.layout = action.payload;
        },
        setApiResponse: (state: RootState, action: PayloadAction<any>) => {
            apiResponseHelper(state, action);
        },
        setDashboardId: (state: RootState, action: PayloadAction<any>) => {
            state.dashboardId = action.payload;
        },
        setWidgetList: (state: RootState, action: PayloadAction<any>) => {
            setDashboardWidgets(state, action);
        },
        setDashboardWidgetIds: (state: RootState, action: PayloadAction<any>) => {
            const currentClone = current(state);
            const selectedId = currentClone?.dashboardWidgetIds?.findIndex((item: any) => item === action.payload);
            if (selectedId === -1) {
                state.dashboardWidgetIds.push(action.payload);
            } else {
                state.dashboardWidgetIds?.splice(selectedId, 1);
            }
        },

        configLayout: (state: RootState, action: PayloadAction<any>) => {
            const layoutClone: any = [];
            const stateClone = current(state);
            stateClone?.widgetList
                ?.filter((item: any) => !item.config?.isPinned)
                ?.map((widget: any, widgetIndex: number) => {
                    const previousLayout = action?.payload?.widgetLayout?.find(
                        (prevWidgetLayout: any) => prevWidgetLayout?.i === widget?.id
                    );
                    const changedLayout = stateClone.layout?.find(
                        (widgetLayout: any) => widgetLayout?.i === widget?.id
                    );

                    if (!changedLayout && !previousLayout) {
                        const previousWidgetLayout: any = state.layout?.[widgetIndex - 1];
                        layoutClone[widgetIndex] = {
                            i: widget?.id,
                            w: 20,
                            h: 7,
                            x: previousWidgetLayout
                                ? previousWidgetLayout?.x + previousWidgetLayout?.w + 20 > GRID_COLS
                                    ? 0
                                    : previousWidgetLayout?.x + previousWidgetLayout?.w
                                : 0,
                            y: previousWidgetLayout
                                ? previousWidgetLayout?.x + previousWidgetLayout?.w + 20 > GRID_COLS
                                    ? previousWidgetLayout?.y + previousWidgetLayout?.h
                                    : previousWidgetLayout?.y
                                : 0,
                            minH: 4,
                            minW: 6,
                        };
                    } else if (changedLayout && !previousLayout) {
                        layoutClone[widgetIndex] = changedLayout;
                    } else if (!changedLayout && previousLayout) {
                        layoutClone[widgetIndex] = previousLayout;
                    } else {
                        layoutClone[widgetIndex] = changedLayout;
                    }
                });

            state.layout = layoutClone;
        },
        removeWidget: (state: RootState, action: PayloadAction<any>) => {
            const widgetsClone = current(state);
            const selectedIndex = widgetsClone?.widgetList?.findIndex((item: any) => item?.id === action.payload);
            const selectedWidgetId = widgetsClone?.dashboardWidgetIds?.indexOf(action.payload);
            const removedWidget = widgetsClone?.removedWidgets?.indexOf(action.payload);

            state.widgetList?.splice(selectedIndex, 1);
            if (selectedWidgetId !== -1) {
                state.dashboardWidgetIds?.splice(selectedWidgetId, 1);
            }
            if (removedWidget === -1) {
                state.removedWidgets.push(action.payload);
            }
        },
        configureValues: (state: RootState, action: PayloadAction<any>) => {
            const stateClone = current(state);

            const selectedIndex = stateClone?.widgetList?.findIndex(
                (item: any) => item?.id === action?.payload?.widgetId
            );
            if (!isNullOrUndefined(selectedIndex) && selectedIndex !== -1 && action?.payload?.config) {
                state['widgetList'][selectedIndex]['config'] = action.payload?.config;
            }
        },
        setWidgetDetailsLoader: (state: RootState, action: PayloadAction<any>) => {
            state['loaders'][action.payload.key] = action.payload.value;
        },
        removeWidgetLoadingState: (state: RootState, action: PayloadAction<any>) => {
            delete state['loaders'][action.payload];
        },
    },
});

export const {
    setLayout,
    removeWidget,
    resetLoaders,
    configLayout,
    setWidgetList,
    setDashboardId,
    setApiResponse,
    configureValues,
    resetLocalDashboard,
    setDashboardWidgetIds,
    setWidgetDetailsLoader,
    removeWidgetLoadingState,
} = dashboardSlice.actions;

export default dashboardSlice;
